import React from 'react';
import { ApolloError, QueryResult } from '@apollo/client';
// import LoggedOutModal from 'common-lib/src/modules/login/components/LoggedOutModal';

import Loading from '@common-lib/components/Loading';
import LoadingError from '@common-lib/components/LoadingError';
import NotFoundError from '@common-lib/components/Error404';
import NoAccessError from '@common-lib/components/Error403';

const defaultOptions = {
	showError: true,
	showLoading: true,
	showErrorOnEmptyData: false,
	hideErrorIcon: false,
	onError: (): undefined => undefined,
};

type displayOptions = {
	showError?: boolean;
	showLoading?: boolean;
	showErrorOnEmptyData?: boolean;
	hideErrorIcon?: boolean;
	onError?: (erro: ApolloError | undefined) => void;
	loadingComponent?: React.ReactElement<any, any>;
	overlay?: boolean;
	errorComponent?: React.ReactElement<any, any>;
};

type processQueryFN = (
	result: Pick<
		QueryResult<any, Record<string, any>>,
		'loading' | 'error' | 'refetch' | 'data' | 'called'
	>,
	displayOptions?: displayOptions,
) => React.ReactElement<any, any> | null | undefined;

const processQuery: processQueryFN = (
	{ loading, error, refetch, data, called },
	displayOptions = defaultOptions,
) => {
	const {
		overlay,
		showError,
		showLoading,
		showErrorOnEmptyData,
		loadingComponent,
		errorComponent,
		hideErrorIcon,
		onError,
	} = {
		...defaultOptions,
		...displayOptions,
	};
	const isCalled = called !== undefined && called !== null ? called : true;
	if (!isCalled) {
		return null;
	}
	const loader =
		loadingComponent || React.createElement(Loading, { overlay });
	if (loading) {
		return showLoading ? loader : null;
	}
	// if we have an error and data, we should go through
	if (error && !data) {
		console.warn(error);
		// if (error.graphQLErrors && error.graphQLErrors[0]?.statusCode === 401) {
		// 	// we have an expired token
		// 	return <LoggedOutModal />;
		// }
		if (showError) {
			onError(error);
			const statusCode =
				(error.graphQLErrors &&
					(error.graphQLErrors[0] as any)?.statusCode) ||
				(error.graphQLErrors[0] as any)?.extensions?.statusCode;
			if (statusCode === 404) {
				return React.createElement(NotFoundError, { overlay });
			}
			if (statusCode === 403) {
				return React.createElement(NoAccessError, { overlay });
			}
			console.warn('process query', error);
			return (
				errorComponent ||
				React.createElement(LoadingError, {
					// overlay,
					hideErrorIcon,
					onRetry: () => refetch(),
				})
			);
		}
		return null;
	}
	if (isCalled && !loading && (!data || Object.keys(data).length === 0)) {
		console.error('Empty data. Aborting render.', data);
		if (!showError || !showErrorOnEmptyData) {
			return null;
		}
		return React.createElement(LoadingError, {
			hideErrorIcon,
			// overlay,
			onRetry: () => refetch(),
		});
	}
};

export default processQuery;

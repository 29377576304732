import React from 'react';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';

import { BoxProps } from '@ui/components/Box';
import {
	Box,
	Button,
	ButtonProps,
	Text,
	TextProps,
	Image,
} from '@ui/components';
import { styled } from '@ui/styles';

import { BrandCode, IEvent, ListQueryResult } from '@interfaces/index';

import QUERY_EVENTS from '@common-lib/modules/event/graphql/queryEvents.graphql';
import Error404 from '@common-lib/components/Error404';
import processQuery from '@common-lib/graphql/processQuery';
import {
	isTimePassed,
	parseToTimezoneDate,
} from '@common-lib/helpers/dateHelper';

const TheBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	flexWrap: 'nowrap',
}));

const Wrapper = styled(Box)(({ theme }) => ({
	background: theme.palette.primary.light,
	borderRadius: 20,
}));

const TheButton = styled(Button)(({ theme }) => ({
	background: theme.palette.primary.lighter,
	':hover': {
		background: theme.palette.primary.light,
	},
}));

const IconWrap = styled(Box)(({ theme }) => ({
	width: theme.spacing(6),
	height: theme.spacing(6),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	[theme.breakpoints.down('sm')]: {
		backgroundColor: theme.palette.primary.lighter,
		borderRadius: theme.spacing(2),
		width: theme.spacing(4),
		height: theme.spacing(4),
		'&>img': {
			width: theme.spacing(4),
			height: theme.spacing(4),
		},
	},
}));

const HomeUpcomingEvent: React.FC<
	Omit<BoxProps, 'ref'> & {
		withBg?: boolean;
		brand?: BrandCode;
		hideLoader?: boolean;
		labelColor?: TextProps['color'];
	} & Pick<ButtonProps, 'color'>
> = ({ brand, withBg, color, labelColor, hideLoader = true, ...props }) => {
	const { data, refetch, loading, ...rest } = useQuery<{
		events: ListQueryResult<IEvent>;
	}>(QUERY_EVENTS, {
		variables: {
			pageSize: 1,
			filter: brand
				? JSON.stringify({
						brandCode: `eq~${brand}`,
						hideHomepage: `eq~false`,
						dteEnd: `ne~null`,
					})
				: JSON.stringify({
						hideHomepage: `eq~false`,
						dteEnd: `ne~null`,
					}),
		},
	});
	const { edges } = data?.events || { edges: [] };
	const { id, label, dteStart, dteEnd, venue, address } =
		edges[0]?.node || {};
	const { push } = useRouter();
	const handleClick = React.useCallback(() => {
		push({
			pathname: '/eventos/[eventId]/cadastro',
			query: { eventId: id },
		});
	}, [push, id]);
	const res = processQuery(
		{ data, refetch, loading, ...rest },
		{ overlay: true },
	);
	if (res !== undefined) {
		return loading && hideLoader ? null : res;
	}
	if (!data?.events) {
		return <Error404 />;
	}
	if (!edges.length) {
		return null;
	}
	return (
		<Wrapper mt={-1} mb={2} pt={2} pb={1} px={2}>
			<TheBox position="relative">
				<IconWrap>
					<Image
						src="/icons/EventIcon.png"
						alt="event icon"
						height={48}
						width={48}
					/>
				</IconWrap>

				<Text
					variant="h5"
					component="div"
					ml={2}
					color="warning.main"
					pb={2}
				>
					{label}
					<br />
					<>
						{isTimePassed(
							parseToTimezoneDate(dteStart),
							'minutes',
						) &&
						(!dteEnd ||
							!isTimePassed(
								parseToTimezoneDate(dteEnd),
								'minutes',
							)) ? (
							<Text
								variant="h6"
								fontWeight="700"
								color="warning.main"
							>
								Acontecendo agora
							</Text>
						) : (
							<Text
								variant="h6"
								fontWeight="700"
								color="warning.main"
							>
								Próximo evento
							</Text>
						)}
					</>
				</Text>
			</TheBox>
			<Box textAlign="center">
				<TheButton
					size="small"
					variant="text"
					color={color}
					onClick={handleClick}
				>
					Quero participar!
				</TheButton>
			</Box>
		</Wrapper>
	);
};

export default HomeUpcomingEvent;

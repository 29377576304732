import React from 'react';

import { styled } from '@ui/styles';
import Box from '@ui/components/Box';

const Wrapper = styled(Box)(({ theme }) => ({
	overflow: 'hidden',
	height: '100vh',
	width: '100%',
	position: 'absolute',
	top: 0,
	willChange: 'transform',
	filter: 'blur(200px)',
	'& *': {
		zIndex: 0,
	},
	zIndex: 0,
}));

const BlueCircle = styled(Box)(({ theme }) => ({
	position: 'absolute',
	width: 200,
	height: 200,
	left: -87,
	top: -17,
	background: 'rgba(100, 76, 153, 0.5)',
	borderRadius: '50%',
	// willChange: 'transform',
	// filter: 'blur(200px)',
}));
const PinkCircleCenter = styled(Box)(({ theme }) => ({
	position: 'absolute',
	width: 300,
	height: 300,
	left: 700,
	top: 400,
	background: '#F29FC5',
	borderRadius: '50%',
	// willChange: 'transform',
	// filter: 'blur(200px)',
}));
const PinkCircleLeft = styled(Box)(({ theme }) => ({
	position: 'absolute',
	width: 400,
	height: 400,
	left: 'auto',
	right: -150,
	top: 300,
	background: theme.palette.warning.dark,
	opacity: 0.2,
	borderRadius: '50%',
	// willChange: 'transform',
	// filter: 'blur(200px)',
}));

const Background: React.FC = () => {
	return (
		<Wrapper>
			<BlueCircle />
			<PinkCircleCenter />
			<PinkCircleLeft />
		</Wrapper>
	);
};

export default Background;

import React from 'react';

import Box, { BoxProps } from '@ui/components/Box';
import LoadingIndicator from '@ui/components/Loading';
import Portal from '@ui/components/Portal';

type Props = {
	overlay?: boolean;
} & Omit<BoxProps, 'ref'>;

const Loading: React.FC<Props> = ({ overlay, children, ...props }) => {
	return overlay ? (
		<Portal>
			<Box
				{...props}
				zIndex={10}
				position="absolute"
				left={0}
				top={80}
				height="100vh"
				width="100vw"
				display="flex"
				flexDirection="column"
				justifyContent="center"
			>
				<Box display="flex" justifyContent="center">
					<LoadingIndicator />
				</Box>
				{children}
			</Box>
		</Portal>
	) : (
		<Box {...props}>
			<LoadingIndicator />
			{children}
		</Box>
	);
};

export default Loading;

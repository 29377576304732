import React from 'react';

import Box, { BoxProps } from '@ui/components/Box';
import Text from '@ui/components/Text';
import Smiley from '@ui/icons/Smiley';
import Portal from '@ui/components/Portal';

type Props = {
	overlay?: boolean;
	iconWidth?: string | number;
	iconHeight?: string | number;
} & Omit<BoxProps, 'ref'>;

const Error404: React.FC<Props> = ({
	overlay,
	iconWidth,
	iconHeight,
	children,
	...props
}) => {
	return overlay ? (
		<Portal>
			<Box
				{...props}
				zIndex={10}
				position="absolute"
				left={0}
				top={80}
				height="100vh"
				width="100vw"
				display="flex"
				flexDirection="column"
				justifyContent="center"
			>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					flexDirection="column"
					mt={-20}
				>
					<Smiley height={iconHeight} width={iconWidth} />
					<Box mt={2} mb={2}>
						{children || (
							<Box mt={2}>
								<Text
									color="warning.main"
									variant="h1"
									fontWeight="bold"
								>
									Não encontrado
								</Text>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</Portal>
	) : (
		<Box
			{...props}
			display="flex"
			flexDirection="column"
			alignItems="center"
		>
			<Smiley height={iconHeight} width={iconWidth} />
			{children || (
				<Box mt={2}>
					<Text
						color="warning.main"
						variant="body1"
						fontWeight="bold"
					>
						Não encontrado
					</Text>
				</Box>
			)}
		</Box>
	);
};

export default Error404;

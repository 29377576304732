import React from 'react';

import Box, { BoxProps } from '@ui/components/Box';
import Portal from '@ui/components/Portal';

type ImgProps = {
	width?: string | number;
	height?: string | number;
};
const Img: React.FC<ImgProps> = ({ width = 210, height = 210 }) => (
	<svg height={height} width={width} xmlns="http://www.w3.org/2000/svg">
		<symbol
			id="403_image"
			width="210"
			height="210"
			viewBox="0 0 210 210"
			fill="none"
		>
			<circle cx="104.702" cy="104.752" r="40" stroke="#E1E1E1" />
			<circle
				opacity="0.8"
				cx="104.702"
				cy="104.752"
				r="72"
				stroke="#E1E1E1"
			/>
			<circle
				opacity="0.5"
				cx="104.702"
				cy="104.752"
				r="104"
				stroke="#E1E1E1"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M122.088 129.252C142.246 129.252 158.588 112.91 158.588 92.752C158.588 72.5936 142.246 56.252 122.088 56.252C101.929 56.252 85.5879 72.5936 85.5879 92.752C85.5879 112.91 101.929 129.252 122.088 129.252Z"
				fill="url(#paint0_linear_2797_735)"
			/>
			<g filter="url(#filter0_f_2797_735)">
				<ellipse
					cx="77.3678"
					cy="140.388"
					rx="10.6666"
					ry="0.639996"
					fill="#A6A4A4"
				/>
			</g>
			<g filter="url(#filter1_f_2797_735)">
				<ellipse
					cx="101.71"
					cy="140.388"
					rx="10.6666"
					ry="0.639996"
					fill="#A6A4A4"
				/>
			</g>
			<rect
				x="61.3953"
				y="92.752"
				width="17.3302"
				height="16.2834"
				fill="#0630C2"
			/>
			<rect
				x="83.5247"
				y="92.752"
				width="17.3302"
				height="16.2834"
				fill="#FF0000"
			/>
			<rect
				x="61.3953"
				y="112.969"
				width="17.3302"
				height="16.2834"
				fill="#FF0000"
			/>
			<rect
				x="83.5247"
				y="112.969"
				width="17.3302"
				height="16.2834"
				fill="#012CC2"
			/>
			<rect
				x="108.239"
				y="85.6191"
				width="27.6981"
				height="19.1329"
				rx="2"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M122.023 96.6273C127.914 96.6273 132.689 91.8517 132.689 85.9607C132.689 80.0698 127.914 75.2942 122.023 75.2942C116.132 75.2942 111.356 80.0698 111.356 85.9607C111.356 91.8517 116.132 96.6273 122.023 96.6273ZM122.023 91.6191C125.336 91.6191 128.023 88.9328 128.023 85.6191C128.023 82.3054 125.336 79.6191 122.023 79.6191C118.709 79.6191 116.023 82.3054 116.023 85.6191C116.023 88.9328 118.709 91.6191 122.023 91.6191Z"
				fill="white"
			/>
			<defs>
				<filter
					id="filter0_f_2797_735"
					x="62.7012"
					y="135.748"
					width="29.3333"
					height="9.28003"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="2"
						result="effect1_foregroundBlur_2797_735"
					/>
				</filter>
				<filter
					id="filter1_f_2797_735"
					x="87.0435"
					y="135.748"
					width="29.3333"
					height="9.28003"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="2"
						result="effect1_foregroundBlur_2797_735"
					/>
				</filter>
				<linearGradient
					id="paint0_linear_2797_735"
					x1="70.2394"
					y1="86.1488"
					x2="115.233"
					y2="143.365"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FEF6EA" />
					<stop offset="1" stopColor="#002AC1" />
				</linearGradient>
			</defs>
		</symbol>
		<use href="#403_image" x="0" y="0" height={height} width={width} />
	</svg>
);

Img.displayName = '403';

type Props = {
	overlay?: boolean;
	iconWidth?: string | number;
	iconHeight?: string | number;
} & Omit<BoxProps, 'ref'>;

const Error403: React.FC<Props> = ({
	overlay,
	iconWidth,
	iconHeight,
	children,
	...props
}) => {
	return overlay ? (
		<Portal>
			<Box
				{...props}
				zIndex={10}
				position="absolute"
				left={0}
				top={80}
				height="100vh"
				width="100vw"
				display="flex"
				flexDirection="column"
				justifyContent="center"
			>
				<Box display="flex" justifyContent="center">
					<Img height={iconHeight} width={iconWidth} />
				</Box>
				{children}
			</Box>
		</Portal>
	) : (
		<Box {...props}>
			<Img height={iconHeight} width={iconWidth} />
			{children}
		</Box>
	);
};

export default Error403;
